<template>
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.888672 1.88892C0.888672 1.33663 1.33639 0.888916 1.88867 0.888916H15.8887C16.441 0.888916 16.8887 1.33663 16.8887 1.88892C16.8887 2.4412 16.441 2.88892 15.8887 2.88892H1.88867C1.33639 2.88892 0.888672 2.4412 0.888672 1.88892Z"
      fill="#2A1F60"
    />
    <path
      d="M0.888672 6.88892C0.888672 6.33663 1.33639 5.88892 1.88867 5.88892H9.88867C10.441 5.88892 10.8887 6.33663 10.8887 6.88892C10.8887 7.4412 10.441 7.88892 9.88867 7.88892H1.88867C1.33639 7.88892 0.888672 7.4412 0.888672 6.88892Z"
      fill="#2A1F60"
    />
    <path
      d="M1.88867 10.8889C1.33639 10.8889 0.888672 11.3366 0.888672 11.8889C0.888672 12.4412 1.33639 12.8889 1.88867 12.8889H9.88867C10.441 12.8889 10.8887 12.4412 10.8887 11.8889C10.8887 11.3366 10.441 10.8889 9.88867 10.8889H1.88867Z"
      fill="#2A1F60"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMenu"
};
</script>
