import { ref, watchEffect } from "vue";
import { db } from "@/firebase.js";

const useDocument = (collection, id) => {
  const document = ref(null);
  const error = ref(null);
  const isPending = ref(false);

  const docRef = db.collection(collection).doc(id);

  const unsub = docRef.onSnapshot(
    doc => {
      if (doc.data()) {
        document.value = { ...doc.data(), id: doc.id };
        error.value = null;
      } else {
        error.value = "that document does not exist";
      }
    },
    err => {
      console.log(err.message);
      error.value = "Could not fetch data";
    }
  );

  watchEffect(onInvalidate => {
    onInvalidate(() => unsub());
  });

  const deleteDoc = async () => {
    isPending.value = true;
    error.value = null;

    try {
      const res = await docRef.delete();
      isPending.value = false;
      return res;
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "could not delete the document";
    }
  };

  const updateDoc = async updates => {
    isPending.value = true;
    error.value = null;

    try {
      const res = await docRef.update(updates);
      isPending.value = false;
      return res;
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "could not update the document";
    }
  };

  return { document, error, isPending, deleteDoc, updateDoc };
};

export default useDocument;
