<template>
  <div class="board__filters">
    <BaseButton v-if="filterAssignedTasks" @click="filterAssignedTasks = false">
      Show all tasks
    </BaseButton>
    <BaseButton v-else @click="filterAssignedTasks = true">
      Show only tasks assigned to me
    </BaseButton>

    <select v-model="criteria">
      <option value="estimatedTime">Est. Time</option>
      <option value="priority">Priority</option>
    </select>

    <select v-model="direction">
      <option value="asc">ASC</option>
      <option value="desc">DESC</option>
    </select>
  </div>
  <div class="board">
    <TaskViewColumn
      v-for="list in project.lists"
      :key="list.key"
      :title="list.title"
      :list="list.key"
      :tasks="getFilteredTasksForList(list.key)"
      :can-delete="canDeleteList(list.key)"
      @end="onDragEnd"
      @update-title="onUpdateColumnTitle(list.key, $event)"
      @create="onCreateTask(list.key)"
      @delete="onDeleteColumn(list.key)"
    />
  </div>
  <button @click="onAddList">Add List</button>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { db, timestamp } from "@/firebase";
import getUser from "@/composables/getUser";
import useSortedTasks from "@/composables/useSortedTasks";

import BaseButton from "@/components/designsystem/BaseButton.vue";
import TaskViewColumn from "@/components/projects/TaskViewColumn.vue";

export default {
  name: "ProjectTasksView",
  components: {
    BaseButton,
    TaskViewColumn
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const { user } = getUser();

    const tasks = ref([]);

    db.collection(`companies/${route.params.companyId}/tasks`)
      .where("projectId", "==", props.project.id)
      .onSnapshot(snap => {
        tasks.value = snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      });

    const criteria = ref("estimatedTime");
    const direction = ref("desc");
    const { sortedTasks } = useSortedTasks({
      tasks: tasks,
      criteria,
      direction
    });

    const filterAssignedTasks = ref(false);

    const getAllTasksForList = list => {
      return sortedTasks.value.filter(task => task.list === list);
    };

    const getFilteredTasksForList = listKey => {
      const allTasksForList = getAllTasksForList(listKey);

      return filterAssignedTasks.value
        ? allTasksForList.filter(task =>
            task.assignees.includes(user.value.uid)
          )
        : allTasksForList;
    };

    const canDeleteList = listKey => {
      const allTasksForList = getAllTasksForList(listKey);
      return allTasksForList.length === 0 && props.project.lists.length > 1;
    };

    const onDragEnd = async event => {
      const { taskId } = event.item.dataset;
      if (!taskId) return;

      const list = event.to.dataset.taskList;
      if (!list) return;

      await db
        .collection(`companies/${route.params.companyId}/tasks`)
        .doc(taskId)
        .update({ list });
    };

    const onUpdateColumnTitle = async (listKey, title) => {
      await db
        .collection(`companies/${route.params.companyId}/projects`)
        .doc(props.project.id)
        .update({
          lists: props.project.lists.map(list => {
            if (list.key === listKey) {
              list.title = title;
            }

            return list;
          })
        });
    };

    const onDeleteColumn = async listKey => {
      const { lists } = props.project;
      await db
        .collection(`companies/${route.params.companyId}/projects`)
        .doc(props.project.id)
        .update({ lists: lists.filter(list => list.key !== listKey) });
    };

    const onCreateTask = async listKey => {
      await db.collection(`companies/${route.params.companyId}/tasks`).add({
        name: "New Task",
        description: "",
        estimatedTime: null,
        assignees: [],
        projectId: props.project.id,
        dueDate: new Date(),
        priority: null,
        list: listKey,
        createdAt: timestamp(),
        userId: user.value.uid
      });
    };

    const onAddList = async () => {
      const { lists } = props.project;
      await db
        .collection(`companies/${route.params.companyId}/projects`)
        .doc(props.project.id)
        .update({
          lists: [
            ...lists,
            { title: "New List", key: new Date().toISOString() }
          ]
        });
    };

    return {
      criteria,
      direction,
      filterAssignedTasks,
      getFilteredTasksForList,
      canDeleteList,
      onDragEnd,
      onUpdateColumnTitle,
      onDeleteColumn,
      onCreateTask,
      onAddList
    };
  }
};
</script>

<style scoped>
.ghost {
  opacity: 0.1;
  background: darkgray;
}
.drop-zone {
  flex-grow: 1;
}
.draggedTask {
  height: 104px;
  margin-bottom: 16px;
}
.linkedTask {
  box-sizing: border-box;
  display: block;
}
.noTasks {
  align-items: center;
  display: flex;
  height: 420px;
}
</style>
