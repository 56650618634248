<template>
  <div class="columnBlock">
    <div class="columnHeader">
      <input
        class="column-title columnTitle"
        type="text"
        v-model="localTitle"
        @keydown.enter="$event.target.blur()"
      />
      <button @click="$emit('create')">+</button>
      <button @click="deleteColumn" :disabled="!canDelete">Remove</button>
    </div>
    <VueDraggable
      class="drop-zone"
      v-model="localTasks"
      group="task-priority"
      item-key="id"
      :sort="false"
      :data-task-list="list"
      @end="$emit('end', $event)"
    >
      <template #item="{element}">
        <TaskCard class="draggedTask" :task="element" />
      </template>
    </VueDraggable>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import debounce from "debounce";
import VueDraggable from "vuedraggable";

import TaskCard from "@/components/commons/TaskCard";

export default {
  components: {
    VueDraggable,
    TaskCard
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    list: {
      type: String,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localTasks = ref([...props.tasks]);
    watch(
      () => props.tasks,
      () => (localTasks.value = props.tasks)
    );

    const deleteColumn = () => props.canDelete && emit("delete");

    const localTitle = ref(props.title);
    watch(
      localTitle,
      debounce(() => emit("update-title", localTitle.value), 500)
    );

    return {
      localTasks,
      localTitle,
      deleteColumn
    };
  }
};
</script>

<style lang="scss" scoped>
.column-title {
  background-color: #0000;
  box-shadow: none;
  transition: border 0.2s ease;
  height: initial;
  line-height: initial;
  padding: 4px 8px;
  margin: 0;
  margin-right: 4px;
  text-transform: none;
  font-size: $t18;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    background-color: #fff;
    box-shadow: inset 0 0 0 2px $base-color-blue;
  }
}

.ghost {
  opacity: 0.1;
  background: darkgray;
}
.drop-zone {
  flex-grow: 1;
}
.draggedTask {
  height: 104px;
  margin-bottom: 16px;
}
.linkedTask {
  box-sizing: border-box;
  display: block;
}
.noTasks {
  align-items: center;
  display: flex;
  height: 420px;
}
</style>
