<template>
  <router-link
    :to="{ name: 'TaskDetails', params: { taskId: task.id } }"
    class="linkedTask"
    :data-task-id="task.id"
  >
    <div class="task-card taskBlock clickable">
      <div
        class="completionLine"
        :style="{ width: `${percentageCompleted}%` }"
      ></div>
      <div class="tTaskBlock">
        <h5>{{ task.name }}</h5>

        <div class="flag">
          <PriorityFlag :priority="task.priority" />
        </div>
      </div>
      <div class="bTaskBlock">
        <div class="duration" v-if="formattedDueDate">
          {{ formattedDueDate }}
        </div>

        <AssigneesProfiles :value="task.assignees" />
      </div>
    </div>
  </router-link>
</template>

<script>
import { computed } from "vue";
import { format } from "date-fns";

import PriorityFlag from "./PriorityFlag.vue";
import AssigneesProfiles from "@/components/commons/AssigneesProfiles.vue";

export default {
  name: "TaskCard",
  components: {
    PriorityFlag,
    AssigneesProfiles
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const formattedDueDate = computed(
      () =>
        props.task.dueDate &&
        format(new Date(props.task.dueDate.seconds * 1000), "MMM d")
    );

    const percentageCompleted = computed(() => {
      if (!props.task.subtasks) return 0;

      return (
        (props.task.subtasks.filter(subtask => subtask.complete).length * 100) /
        props.task.subtasks.length
      );
    });

    return { formattedDueDate, percentageCompleted };
  }
};
</script>

<style lang="scss" scoped>
.linkedTask {
  background-color: white;
  box-sizing: border-box;
  display: block;
  height: 100%;
}

.completionLine {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: red;
}
</style>
