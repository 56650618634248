<template>
  <div class="container">
    <pre>{{ error }}</pre>
    <div class="wrapper">
      <div class="projectsNav" v-if="project">
        <div class="backLink">
          <a @click="$router.go(-1)" class="clickable">Back</a>
        </div>

        <div class="verticalSep"></div>

        <div class="projTitle">
          <h4>{{ project.name }}</h4>
        </div>

        <div class="members">
          <AssigneesProfiles :value="project.assignees" />
        </div>

        <div class="projMore clickable">
          <IconMenu @click="toggleNav" />
          <div v-if="openNav" class="miniNav clickable">
            <div v-if="project" v-click-away="onClickAway">
              <ul>
                <li>
                  <a v-if="isOwner" @click="deleteProject">Delete project</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <PriorityFlag :priority="project.priority" />

        <div>
          <EditProjects :project="project" />
        </div>
      </div>

      <ProjectTasksView v-if="project" :project="project" />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useRouter, useRoute } from "vue-router";

import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";

import useDocument from "@/composables/useDocument";

import IconMenu from "@/components/icons/IconMenu.vue";

import AssigneesProfiles from "@/components/commons/AssigneesProfiles.vue";
import PriorityFlag from "@/components/commons/PriorityFlag.vue";
import EditProjects from "@/components/projects/EditProjects.vue";
import ProjectTasksView from "@/components/projects/ProjectTasksView.vue";

export default {
  components: {
    IconMenu,
    ProjectTasksView,
    EditProjects,
    AssigneesProfiles,
    PriorityFlag
  },
  setup() {
    const toast = inject("$toast");
    const router = useRouter();
    const route = useRoute();
    const { user } = getUser();

    const openNav = ref(false);
    const onClickAway = () => {
      openNav.value = false;
    };

    const { document: project, error, deleteDoc } = useDocument(
      `companies/${route.params.companyId}/projects`,
      route.params.projectId
    );

    const { loading, documents: tasks } = getCollection(
      `companies/${route.params.companyId}/tasks`
    );

    const isOwner = computed(
      () =>
        project.value && user.value && user.value.uid == project.value.userId
    );

    const deleteProject = async () => {
      deleteDoc();
      await toast("Project successfully deleted", { type: "success" });
      router.go(-1);
    };

    return {
      error,
      project,
      loading,
      tasks,
      isOwner,
      deleteProject,
      openNav,
      onClickAway
    };
  },
  methods: {
    toggleNav() {
      this.openNav = !this.openNav;
    }
  },
  data() {
    return {
      tabs: ["CardsView", "ListView"],
      selected: "CardsView"
    };
  }
};
</script>

<style lang="scss" scoped>
.projectsNav {
  @include flex-row;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
}

.miniNav {
  background: white;
  border-radius: 3px;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.15),
    0px 0px 6px rgba(0, 0, 0, 0.15);
  display: block ruby;
  position: absolute;
  top: 32px;
}
.miniNav ul {
  width: 204px;
}
.miniNav li {
  display: flex;
}
.miniNav a {
  color: #2a1f60;
  line-height: 32px;
  padding: 8px 32px;
  width: 100%;
}
.miniNav a:hover {
  background: #2a1f60;
  color: white;
}
.urgent {
  background: $base-color-red;
  color: $base-color-red;
}
.high {
  background: $base-color-orange;
  color: $base-color-orange;
}
.medium {
  background: $base-color-yellow;
  color: $base-color-yellow;
}
.low {
  background: $base-color-green;
  color: $base-color-green;
}
</style>
