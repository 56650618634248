<template>
  <div>
    <BaseButton
      v-if="!showForm"
      class="smallUpdate"
      modifiers="primary"
      @click="showForm = true"
    >
      Update details
    </BaseButton>
    <div v-if="showForm" class="edit-project">
      <div class="form-wrapper">
        <CloseModal @close="showForm = false" />
        <form @submit.prevent="updateProjectDetails">
          <h4>Update this project</h4>
          <label>Project title</label>
          <input
            type="text"
            placeholder="Project title"
            required
            v-model="form.name"
          />
          <label>Description</label>
          <textarea
            type="text"
            placeholder="Description"
            maxlength="135"
            v-model="form.description"
          />
          <label>Priority</label>
          <select
            v-model="form.priority"
            placeholder="project.priority"
            required
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
            <option value="urgent">Urgent</option>
          </select>
          <label>Assignees</label>
          <select v-model="form.assignees" multiple>
            <option
              v-for="user in companyUsers"
              :value="user.userId"
              :key="user.userId"
            >
              {{ user.firstName }} {{ user.lastName }}
            </option>
          </select>
          <BaseButton modifiers="primary">Update</BaseButton>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { useRoute } from "vue-router";

import useDocument from "@/composables/useDocument";
import getCollection from "@/composables/getCollection";
import useCompanies from "@/composables/useCompanies";

import CloseModal from "@/components/designsystem/CloseModal";
import BaseButton from "@/components/designsystem/BaseButton";

export default {
  components: {
    CloseModal,
    BaseButton
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ""
    }
  },
  setup(props) {
    const route = useRoute();
    const showForm = ref(false);

    const name = ref("");
    const description = ref("");
    const priority = ref("");
    const assignees = ref("");

    const { updateDoc } = useDocument(
      `companies/${route.params.companyId}/projects`,
      props.project.id
    );

    const { company } = useCompanies();
    const { documents: companyUsers } = getCollection("users", [
      "userId",
      "in",
      company.value.members
    ]);

    const form = reactive({
      name: props.project.name,
      description: props.project.description,
      priority: props.project.priority,
      assignees: props.project.assignees
    });

    const updateProjectDetails = async () => {
      await updateDoc({
        name: form.name,
        description: form.description,
        priority: form.priority,
        assignees: form.assignees
      });
      showForm.value = false;
    };

    // const handleSubmit = async () => {
    //   const res = await updateDoc({
    //     name: name.value,
    //     description: description.value,
    //     priority: priority.value,
    //     assignees: assignees.value
    //   });
    //   console.log(res);
    //   showForm.value = false;
    //   name.value = "";
    //   description.value = "";
    //   priority.value = "";
    //   assignees.value = "";
    // };

    return {
      updateProjectDetails,
      form,
      showForm,
      name,
      assignees,
      description,
      priority,
      companyUsers
    };
  }
};
</script>

<style lang="scss" scoped>
textarea {
  margin-bottom: 16px;
}
.edit-project {
  position: absolute;
  width: 100%;
  background-color: rgba(42, 31, 96, 0.9);
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 1000;
  top: 0;
  display: flex;
  height: 100%;
}
.form-wrapper {
  background: white;
  border-radius: 9px;
  padding: 32px;
  position: relative;
}
h4 {
  margin-bottom: 24px;
}
form {
  width: 520px;
  max-width: 100%;
  text-align: left;
}
input,
select {
  margin-bottom: 12px;
}
.smallUpdate {
  background: none;
  color: $base-color;
}
</style>
